

















































































import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";
export default Vue.extend({
  name: "generalDetails",
  props: ["handleTypeGeneralDetail"],
  data() {
    return {
      plainOptions: [
        {
          label: "Customer",
          value: "Customer Details",
        },
        {
          label: "Supplier",
          value: "Supplier Details",
        },
        {
          label: "Employee",
          value: "Employee Details",
        },
      ],
      formRules: {
        type: {
          label: "lbl_type",
          name: "type",
          placeholder: "lbl_type_placeholder",
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        firstName: {
          label: "lbl_first_name",
          name: "firstName",
          placeholder: "lbl_first_name_placeholder",
          decorator: [
            "firstName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        lastName: {
          label: "lbl_last_name",
          name: "lastName",
          placeholder: "lbl_last_name_placeholder",
          decorator: [
            "lastName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        email: {
          label: "lbl_email",
          name: "email",
          placeholder: "lbl_email_placeholder",
          decorator: [
            "email",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                // {
                //   validator: emailValidator,
                // }
              ],
            },
          ],
        },
        phoneNumber: {
          label: "lbl_phone_number",
          name: "phoneNumber",
          placeholder: "lbl_phone_placeholder",
          decorator: [
            "phoneNumber",
            {
              rules: [
                // {
                //   validator: phoneValidator,
                // }
              ],
            },
          ],
        },
        mobileNumber: {
          label: "lbl_mobile_number",
          name: "mobileNumber",
          placeholder: "lbl_mobile_number_placeholder",
          decorator: [
            "mobileNumber",
            {
              rules: [
                // {
                //   validator: phoneValidator,
                // }
              ],
            },
          ],
        },
        faxNumber: {
          label: "lbl_fax_number",
          name: "faxNumber",
          placeholder: "lbl_fax_number_placeholder",
          decorator: ["faxNumber"],
        },
        taxRegistrationNumber: {
          label: "lbl_tax_registration_number",
          name: "taxRegistrationNumber",
          placeholder: "lbl_tax_registration_number_placeholder",
          decorator: [
            "taxRegistrationNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        taxRegistrationName: {
          label: "lbl_tax_registration_name",
          name: "taxRegistrationName",
          placeholder: "lbl_tax_registration_name_placeholder",
          decorator: [
            "taxRegistrationName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    onChangeCheckBox(value) {
      this.handleTypeGeneralDetail(value);
    },
  },
});
